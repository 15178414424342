import React, { useState, useEffect } from "react";
import DataService from "../../services/api/data_services";
import Layout from "../../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import Infoview from "../../templates/InfoView";
import Megaview from "../../templates/MegaView";
import Miniview from "../../templates/MiniView";
import Button from "../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import LeftGrid from "../../templates/LeftGrid";
import { BASE_SITE_URL } from "../../shared/constants";
//Image Banner
import ImageBanner from "../../templates/ImageBanner";
import ImageBnr from "../../assets/Images/Industry/Startup/banner.jpg";

//Mini Image Banner1
import MiniImageBanner1 from "../../templates/MiniImageBanner1";
import MiniImageBannerBgImg from "../../assets/Images/WhatWeDo/who_we_work_with.png";
//Six Hover Cards
import SixPlainCards from "../../templates/SixPlainCards";
import HoverCardWithBtnIcon2 from "../../assets/Images/Industry/Startup/Group -1.svg";
import HoverCardWithBtnIcon4 from "../../assets/Images/Industry/Startup/Group 2827.svg";
import HoverCardWithBtnIcon3 from "../../assets/Images/Industry/Startup/Group 5813.svg";
import HoverCardWithBtnIcon5 from "../../assets/Images/Industry/Startup/Group 3119.svg";
import HoverCardWithBtnIcon1 from "../../assets/Images/Industry/Startup/Group 5721.svg";

//Two column contact info
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import Clm1Img from "../../assets/Icons/phone.svg";
import Clm2Img from "../../assets/Icons/mail.svg";

//BlurCardList
import BlurCardList from "../../templates/BlurCardList";
import MiniCardBgImage3 from "../../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import ogimage from "../../assets/Images/ogImages/startup@2x.png";
// Base url

const Startups = () => {
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [whitePaperRawData, setWhitePaperRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articlePage, setArticlePage] = useState(1);
  const [whitePaperPage, setWhitePaperPage] = useState(1);
  const [articleLimit, setArticleLimit] = useState(3);
  const [whitePaperLimit, setWhitePaperLimit] = useState(1);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=${whitePaperLimit}&page=${whitePaperPage}&type=White Paper&industry_type=Startups`
    ).then((response) => {
      setMegaViewData([...megaviewData, ...response.data]);
      setWhitePaperRawData(response);
    });
  }, [whitePaperPage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${articlePage}&type=Article&industry_type=Startups`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
  }, [articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${page}&type=Client Story&industry_type=Startups`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /**
   * Infoview
   */
  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: ImageBnr,
    altImg: ImageBnr,
    // component:'Who',
    //   bnrImgTitle: (
    //     <> Realty</>
    //  ),
    bnrImgSubTitle: "Industries",
    // servicesColumn1: {
    //   data: [
    //     {
    //       service: "Startups",
    //       link: "/who-we-work-with/startups"
    //     },
    //     {
    //       service: "Finance",
    //       link: "/who-we-work-with/finance"
    //     },
    //     {
    //       service: "Hospitality",
    //       link: "/who-we-work-with/hospitality"
    //     },
    //     {
    //       service: "Not-For-Profit",
    //       link: "/who-we-work-with/not-for-profit"
    //     }
    //   ]
    // },
    // servicesColumn2: {
    //   data: [
    //     {
    //       service: "Healthcare",
    //       link: "/who-we-work-with/healthcare"
    //     },
    //     {
    //       service: "Education",
    //       link: "/who-we-work-with/education"
    //     },
    //     {
    //       service: "Real Estate & PropTech",
    //       link: "/who-we-work-with/real-estate-and-proptech"
    //     },
    //   ]
    // },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Retail and E-commerce",
    //       link: "/who-we-work-with/retail-and-e-commerce"
    //     },
    //     {
    //       service: "Media and Publishing",
    //       link: "/who-we-work-with/media-and-publishing"
    //     },
    //     {
    //       service: "Public Sector",
    //       link: "/who-we-work-with/public-sector"
    //     }
    //   ]
    // }
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        }, // this is temporary
        // {
        //   service: "Finance",
        //   link: "/who-we-work-with/finance"
        // },
        // {
        //   service: "Hospitality",
        //   link: "/who-we-work-with/hospitality"
        // },
        // {
        //   service: "Not-For-Profit",
        //   link: "/who-we-work-with/not-for-profit"
        // }
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        }, // this is temporary
        // {
        //   service: "Education",
        //   link: "/who-we-work-with/education"
        // },
        // {
        //   service: "Real Estate & PropTech",
        //   link: "/who-we-work-with/real-estate-and-proptech"
        // },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        // {
        //   service: "Media and Publishing",
        //   link: "/who-we-work-with/media-and-publishing"
        // },
        // {
        //   service: "Public Sector",
        //   link: "/who-we-work-with/public-sector"
        // }
      ],
    },
  };

  const InfoviewData = {
    route: "/what-we-do",
    color: "primary",
    tooltip: "yes",
    tag: "h2",
    width: "11%",
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Sta</span>rtups
      </div>
    ),
    InfoviewContent: (
      <>
        We became a starting point for many “next big things” while it is small.
        We support startup founders at every stage of the lean startup based
        product development process which starts from prototyping to building
        Minimum Viable Product (MVP).
      </>
    ),
  };
  /**


  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
    ],
  };
  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    IsPlainCard: "yes",
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "Get in Touch",
    HoverCardWithBtntitle: (
      <>
        Need help <br /> with your <br /> MVP idea?
      </>
    ),
    HoverCardWithBtntitle1: "Project Documentation",
    HoverCardWithBtntitle2: "Architecture Design",
    HoverCardWithBtntitle3: "API and Code Documentation",
    HoverCardWithBtntitle4: (
      <>
        Ui <br /> Components, Assets Transfer
      </>
    ),
    HoverCardWithBtntitle5: "Test Reports",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContent1: "SRS and release plan Document",
    HoverCardWithBtnContent2: "Online Database Architecture Design",
    HoverCardWithBtnContent3:
      "A complete granular level of code maintenance will be done throughout the project with Gitlab’s Version Control System maintaining the entire history of code changes done by each developer creating more transparency and accountability.",
    HoverCardWithBtnContent4:
      "Icons, fonts etc. to any low fidelity or high fidelity mockups available, every asset in terms of UI/UX will be documented by Digiryte.",
    HoverCardWithBtnContent5: (
      <>
        Complete Project Testing report including Debugging (Unit & Integration
        Testing), Test cases updated with results Defect reports & Completed RTM
        with the execution status
      </>
    ),
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    whiteCard: "yes",
    lastCardData: {
      service: "machineLearning",
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      tooltip: "yes",
      tip: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };
  const MiniImageBanner1Data1 = {
    title: (
      <span>
        <span className="black-line3letter">How</span> we do it
      </span>
    ),
    titleClass: "mini-image-banner-title-area3 min-title-header",
    image: MiniImageBannerBgImg,
    altTxt: "How we do it",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/how-we-do-it",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  return (
    <Layout
      seo={{
        title: "Digiryte | Startups - Change the world with innovations",
        description:
          "We support startup founders at every stage of the lean startup based on the product development process, From prototyping to building a Minimum Viable Product.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="body-container mt-70">
        <h2 className="title-header">
          <span className="red-line3letter red-3letter-mbl">Sta</span>rtup
          Services{" "}
        </h2>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="body-container mt-70">
        <h2 className="title-header">
          Things That Will be Provided by <br />{" "}
          <span className="red-line3letter red-3letter-mbl">Dig</span>iryte
          Throughout The Project
        </h2>
      </div>
      <div className="mt-50"></div>
      <SixPlainCards SixHoverCardData={SixHoverCardData} />
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70 body-container">
          <h3 className="title-header">
            {" "}
            <span className="red-line3letter red-3letter-mbl">Sta</span>rtup
            Stories
          </h3>
          <div className="mt-50"></div>
        </div>
      )}

      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-50">
          <Miniview
            miniviewData={clientStoryData}
            miniviewData2={miniviewDataArticle}
            quotedMiniCardData={quotedMiniCardData}
            navigateComponent={<LeftGrid />}
          />
        </div>
      )}
      {clientStoryRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE CLIENT STORIES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="MORE CLIENT STORIES"
              tip="MORE CLIENT STORIES"
              tooltip="yes"
            />
          </div>
        </div>
      )}
      {megaviewData && megaviewData.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData} />
        </div>
      )}
      {whitePaperRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setWhitePaperPage(whitePaperPage + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <div className="mt-20">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data1}
        />
      </div>
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(articlePage + 1);
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default Startups;
